import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Products from '../components/Products/Products'
import Footer from '../components/Footer/Footer'
import CopyRight from '../components/Footer/CopyRight'
import './css/Shop.css'

function Shop() {
  return (
    <div className="Shop">
      <Navbar />
      <div className="content screen-big">
        <Products />
      </div>
      <Footer/>
      <CopyRight/>
    </div>
  )
}

export default Shop