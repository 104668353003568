import React from "react";
import Collapsible from "react-collapsible";
import "./Faq.css";

function Faq() {
  return (
    <div className="Faq">
      <Collapsible className="collapsible" trigger="Open Sesame Principles">
        <p>
          Since our company’s beginnings, we are committed to creating only the
          best products that serve people and society. The following are our
          founding principles:
          <br />
          <br />
          1. Do not use any additives and preservatives.
          <br /> 
          2. Take all the necessary time and care to create the best products
          <br /> 
          3. Make products with transparency and integrity
        </p>
      </Collapsible>
      <Collapsible className="collapsible" trigger="Our Vision">
        <p>
        To be a trailblazer and esteemed business in developing high superior sesame centred products and cultivated with global prominence.
        </p>
      </Collapsible>
      <Collapsible className="collapsible" trigger="Our Mission">
        <p>
        We seed sesame seeds to small scale farmers, buy the seed back, value innovate, and supply local and international markets creating smiles and healthy lifestyles.
        </p>
      </Collapsible>
      <Collapsible className="collapsible" trigger="Core Values">
        <p>
        Innovation and drive for quality
        </p>
      </Collapsible>
      <Collapsible className="collapsible" trigger="Integrity">
        <p>
        We expect ethical behaviour from the whole value chain.
        </p>
      </Collapsible>

      <Collapsible className="collapsible" trigger="Corporate Citizenship">
        <p>
        To honour the organisation’s obligation to society, by being an economic, intellectual and social asset to each business connection and relationship
        </p>
      </Collapsible>
      <Collapsible className="collapsible" trigger="Growth">
        <p>
        In a country of increasing agricultural needs, it is important for the organisation to take advantage of serving these societal needs.
        </p>
      </Collapsible>
      <Collapsible className="collapsible" trigger="Profit">
        <p>
        It is necessary to generate the retained earnings needed to achieve our objectives, and thus serve our public purpose.
        </p>
      </Collapsible>
    </div>
  );
}

export default Faq;
