import { createSlice } from '@reduxjs/toolkit';


function clean(obj) {
    return Object.keys(obj)
        .filter(function (key) {  
            return obj[key] !== 0
        })
        .reduce(function (out, key) { 
            out[key] = obj[key]
            return out
        }, {})
}

export const cartSlice = createSlice({
    name: "cart",
    initialState: {},
    reducers: {
        addToCart: (state, action) => {
            if( state[action.payload] ){
                let value = state[action.payload] + 1
                return {
                    ...state,
                    [action.payload]: value,
                }
            }else{
                return {
                    ...state,
                    [action.payload]: 1,
                }
            }
        },
        negateFromCart: (state, action) =>{
            if( state[action.payload] ) {
                let value = (state[action.payload] < 1) ? 0 : state[action.payload] - 1
                return clean({
                    ...state,
                    [action.payload]: value,
                })
            }else{
                return state
            }
        }
    }
});


export const { addToCart } = cartSlice.actions;
export const { negateFromCart } = cartSlice.actions;
export default cartSlice.reducer;