import React from 'react'
import Background from './../../assets/Backgrounds/clean-background.png'
import MobileImage from './../../assets/Backgrounds/all-products.jpg'
import { Link } from 'react-router-dom'
import './Hero.css'

function Hero() {
  return (
    <div className="Hero">
        <img src={Background} className="desktop" alt="background"/>
        <img src={MobileImage} className="mobile" alt="products"/>
        <div className="content">
          <div className="wrapped">
            <h1>We are Sesame</h1>
            <p>Join our sesame family and enjoy a healthy diet throughout the day</p>
            <Link to='/shop'><button>Join Now</button></Link>
          </div>
        </div>
    </div>
  )
}

export default Hero