import "./ShoppingCards.css";
import { useSelector } from "react-redux";
import { products } from "./../../api/products";
import CartCard from "./CartCard";
import EmptyCard from './EmptyCard'
import { Link } from "react-router-dom";

function ShoppingCards() {
  const cart = useSelector((state) => state);

  const totalPrice = obj => {
    delete obj._persist;
    let total=0;
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        total += obj[key]*parseFloat(products[key]?.price);
        // total += obj[key]*parseFloat(10);
      }
    }

    return total;
  }
  
  return (
    <div className="ShoppingCards">
      <h1>Cart</h1>
      <div className="allcards">
        {Object.keys(cart).length > 1 ? 
            <div>
              { 
              Object.entries(cart).map(([key, value], i) => (
                key!=='_persist' && value !== 0 ? <CartCard key={i} details={products[key]} quantity={value} selector={key} /> : ""
              ))
              }
              <div className="total-price">
                <h3><span>Total:</span> ${totalPrice({...cart})}</h3>
              </div>
              <div className="checkout">
                <Link to="/checkout"><button>Proceed to Checkout</button></Link>
              </div>
            </div>
          : <EmptyCard /> }
      </div>
    </div>
  );
}

export default ShoppingCards;
