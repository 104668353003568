import React from "react";
import "./FounderCard.css";

function FounderCard(props) {
  return (
    <div className="FounderCard">
      <div className="image">
        <img src={props.details.profile} alt="Founder" />
      </div>
      <div className="content">
        <h2>{props.details.name}</h2>
        <p className="position">{props.details.position}</p>
        {props.details.name === "Brendan Smith" ? (
          <p>
            Has worked in the commodity business for the past 16 years.
            <br />
            <br />
            <b>Previous Experience:</b>
            <br />
            <b>Selby Enterprises:</b> January 2003 to January 2006.
            <br />
            <b>Commodities:</b> Exporting various types of fruit, oranges,
            lemons and avocado pears
            <br />
            <b>Capsicum:</b> January 2006 to September 2008 <br />
            <b>Paprika The Better Flower Company:</b>  February 2010
            to April 2010.
            <br />
            <b>Commodities:</b> Variety of flowers from South Africa to Dubai.
            <br />
            <b>Prime seed:</b> September 2010 to February 2013
            <br />
            <b>Commodities:</b> Cowpeas, Sesame, Jugo Beans, Sugar Beans, Ground
            nuts, Millet, Rapoko, Sunhemp
            <br />
            <b>Sidella Trading:</b> April 2013 to April 2015.
            <br />
            <b>Open Sesame:</b> April 2015 to present. <br />
            <br />
            In addition to the above, Brendan Smith has also worked with various
            organizations over the past 7 years such as NGO’s on small scale
            crop production. ACDI – VOCA: cowpeas – Mutoko. (Mashonaland East
            Province) GIZ GERMANY: sesame, groundnuts – Manicaland Province.
            ZIMAIED (Branch of USAIED): groundnuts Mutoko. (Mashonaland East
            Province) SNV NETHERLANDS: sesame, cowpeas – Muzarabani and Mbire
            (Mashonaland Central Province)
          </p>
        ) : (
          <p>
            Has worked in the Agro-processing industry since January 2006 up to
            date
            <br />
            <br />
            <b>Previous Experience:</b> <br />
            <b>Capiscum:</b> 2006 -2010 (Processing of Paprika) <br />
            <b>Primeseed:</b> 2010-2013 (Projects Manager in contract farming
            with small holder farmers)
            <br />
            <b>Primeseed:</b> 2013-2014 (Sales and Marketing Manager for
            vegetable seeds, Small grains etc)
            <br />
            <b>Seedco:</b> 2014-to Present (Sales Manager for Vegetable seeds)
            <br />
            <b>Open Sesame:</b> January 2014 to Present (Director Open Sesame){" "}
            <br />
            <br />
            Have worked with a number of NGOs like: CLUSA USAID funded Sesame
            production Muzarabani and Mbire SNV Sesame production Muzarabani.
            GIZ Sesame production Mutasa District. ACDI AVOCA USAID funded
            Cowpeas and Sesame production Mutoko District. ZIMAEID PROGRAM USAID
            funded groundnuts and Sesame production Mutoko District.
          </p>
        )}
      </div>
    </div>
  );
}

export default FounderCard;
