import React from 'react'
import './EmptyCard.css'
import CartEmpty from './../../assets/UI/cart.png'
import { Link } from 'react-router-dom'

function EmptyCard() {
  return (
    <div className="EmptyCard">
        <h3>Your Cart is Empty</h3>
        <img src={CartEmpty} alt="Empty" />
        <Link to={'/shop'}><button>Continue Shopping</button></Link>
    </div>
  )
}

export default EmptyCard