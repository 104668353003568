import React from 'react'
import Navbar from './../components/Navbar/Navbar'
import Describe from './../components/Team/Describe'
import Founders from '../components/Team/Founders'
import Footer from './../components/Footer/Footer'
import CopyRight from '../components/Footer/CopyRight'
import './css/Team.css'

function Team() {
  return (
    <div className="Team">
      <Navbar />
      <div className="content screen-big">
        <Describe />
        <Founders />
      </div>
      <Footer />
      <CopyRight />
    </div>
  )
}

export default Team