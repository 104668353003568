import React from "react";
import Navbar from "./../components/Navbar/Navbar";
import ShoppingCards from "../components/Cart/ShoppingCards";
import Footer from './../components/Footer/Footer'
import CopyRight from './../components/Footer/CopyRight'

import "./css/Cart.css";

function Cart() {
  return (
    <div className="Cart">
      <Navbar />
      <div className="content screen-big">
        <ShoppingCards />
      </div>
      <Footer />
      <CopyRight />
    </div>
  );
}

export default Cart;
