import { Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./pages/Home";
import Shop from "./pages/Shop";
import About from "./pages/About";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import Account from "./pages/Account";
import Cart from "./pages/Cart";
import FarmersCorner from './pages/FarmersCorner'
import AboutSesame from './pages/AboutSesame'
import Checkout from "./pages/Checkout";
import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="shop" element={<Shop />} />
        <Route path="about" element={<About />} />
        <Route path="team" element={<Team />} />
        <Route path="contact" element={<Contact />} />
        <Route path="account" element={<Account />} />
        <Route path="cart" element={<Cart />} />

        <Route path="farmers-corner" element={<FarmersCorner />} />
        <Route path="about-sesame" element={<AboutSesame />} />

        <Route path="checkout" element={<Checkout />} />

        <Route path="*" element={<Home />} />
      </Routes>

      <WhatsAppWidget 
      phoneNumber='+263772813232'
      companyName='opensesame'
       />
    </div>
  );
}

export default App;
