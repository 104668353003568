import React from 'react'
import './Collections.css'
import ProductCard from '../Products/ProductCard'

function BestSelling(props) {

  const collection_products = Object.keys(props.collection).map(key => 
      <ProductCard key={props.collection[key].id} details={props.collection[key]} selector={key}></ProductCard>
  )

  return (
    <div className="Collections">
        <h1>{props.head}</h1>
        <div className="allproducts">
            {collection_products}
        </div>
    </div>
  )
}

export default BestSelling