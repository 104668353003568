import React from 'react'
import './ContactForm.css'
import toast, { Toaster } from 'react-hot-toast';

function ContactForm() {
  
    let submitForm = (e) =>{
        e.preventDefault();
        toast.success('Message Sent.');
    }

  return (
    <div className="ContactForm">
        <h1>Contact Us</h1>
        <form onSubmit={submitForm}>

            <div className="name">
                <div className="first">
                    <p>First Name</p>
                    <input type="text" name="First Name" id="first_name" required/>
                </div>
                <div className="second">
                    <p>Last Name</p>
                    <input type="text" name="Last Name" id="last_name" required/>
                </div>
            </div>

            <div className="email">
                <p>Email</p>
                <input type="email" name="Email" id="email" required/>
            </div>

            <div className="textarea">
                <p>Message/Feedback</p>
                <textarea name="Message" id="message" cols="30" rows="10" required></textarea>
            </div>

            <input type="submit" value="Submit" />

        </form>

        <Toaster position='bottom-right'/>
    </div>
  )
}

export default ContactForm