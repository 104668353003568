import React from 'react'
import './CartCard.css'
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { addToCart, negateFromCart } from '../../redux/reducers/cartReducer';
import {AiOutlinePlus} from 'react-icons/ai'
import {AiOutlineMinus} from 'react-icons/ai'

function CartCard(props) {

  const dispatch = useDispatch()

  const add = () => {
    toast.success('Added to Cart.');
    dispatch(addToCart(props.selector))
  }

  const reduce = () =>{
    toast.success('Removed from Cart.');
    dispatch(negateFromCart(props.selector))
  }

  return (
    <div className="CartCard">
        <div className="image">
          <img src={props.details.image} alt="Product" />
        </div>
        <div className="details">
          <div className="info">
            <h2>{props.details.name}</h2>
            <p>{props.details.quantity}</p>
          </div>
          <div className="counter">
            <button onClick={add}><AiOutlinePlus/></button>
            <p>{props.quantity}</p>
            <button onClick={reduce}><AiOutlineMinus/></button>
          </div>
          <div className="price">
            <h2>${props.details.price} <span> /item</span></h2>
          </div>
          <div className="total">
            <h2><span className='only-mobile'>Subtotal: </span> ${(parseFloat(props.details.price)*parseInt(props.quantity)).toFixed(2)}</h2>
          </div>
        </div>

        <Toaster position='bottom-right'/>
    </div>
  )
}

export default CartCard