import React from 'react'
import './Join.css'
import {FaShippingFast} from 'react-icons/fa'
import {FaCertificate} from 'react-icons/fa'
import {MdSavings} from 'react-icons/md'
import {AiFillShopping} from 'react-icons/ai'
import { Link } from 'react-router-dom'

function Join() {
  return (
    <div className="Join">
        <div className="left">
            <p>Best Quality Products</p>
            <h1>Join the Sesame Movement!</h1>
            <p>Sesame natural seeds: tiny oil rich seeds with various health benefits. 
                Ingredients inclusive of fiber, plant protein, vitamins and antioxidants. </p>
            <Link to="/shop"><button>Shop Now</button></Link>
        </div>

        <div className="right">
            <div className="card">
                <div className='head'><FaShippingFast className='icons'/><h3>Free Shipping</h3></div>
                <p>Above $5000 only</p>
            </div>
            <div className="card">
                <div className='head'><FaCertificate className='icons'/><h3>Certified Natural</h3></div>
                <p>100% Gurantee</p>
            </div>
            <div className="card">
                <div className='head'><MdSavings className='icons'/><h3>Huge Savings</h3></div>
                <p>At Lowest Price</p>
            </div>
            <div className="card">
                <div className='head'><AiFillShopping className='icons'/><h3>Easy Returns</h3></div>
                <p>No Questions Asked</p>
            </div>
        </div>
    </div>
  )
}

export default Join