import React from 'react'
import './Off.css'
import { Link } from 'react-router-dom'

function Off() {
  return (
    <div className="Off">
        <h1>Get 5% Off On Your First Purchase</h1>
        <Link to="/shop"><button>Shop Now</button></Link>
    </div>
  )
}

export default Off