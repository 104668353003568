import React from "react";
import Navbar from "./../components/Navbar/Navbar";
import Footer from "./../components/Footer/Footer";
import CopyRight from "./../components/Footer/CopyRight";
import Faq from "../components/About/Faq";
import Sesame from './../assets/Sesame/sesame-about.png'
import "./css/About.css";
import { Link } from "react-router-dom";
import {AiFillCheckCircle} from 'react-icons/ai'

function About() {
  return (
    <div className="About">
      <Navbar />
      <div className="content-main screen-big">
        <div className="button-group">
          <Link to="/farmers-corner">Farmer's Corner</Link>
          <Link to="/about-sesame">About Sesame</Link>
        </div>
        <div className="description">
          <h1>About Us</h1>
          <div className="section">
            <div>
              <h2>OPEN SESAME</h2>
              <p>
                From Zimbabwe, lying north of the Tropic of Capricorn, is
                completely within the tropics but enjoys subtropical conditions
                because of its high average elevation. The aromatic flavour of
                sesame seeds has been treasured and used for numerous dishes
                around the globe. Open Sesame founded in 2008 with more than 13
                years in existence is the top brand of Zimbabwe manufacturing
                products from sesame seeds, including sesame oil, due to unique
                methods. Centred on the well-being and trustworthiness
                associated with our brand, we have developed an extensive
                assortment of products for either home-based and professional
                use, offering fragrant flavour and the promise of health to
                meal-tables all over the world. <br />
                <br />
                Currently the World’s worry is intensifying over food safety and
                security, owing to using sesame seeds, comprehensively
                monitoring product excellence with the up-to-date facilities,
                applying exceptional and stringent wellbeing and quality
                standards and displaying concern for the environment, Open
                Sesame aims to be a company that contributes to the realization
                of a healthier and wealthier nutritional life for societies as
                bordered in its corporate philosophy. In the present day, with
                increasing public concern over health, sesame is also in the
                limelight as a health nutriment offering good diet. Being mavens
                of sesame seeds, we will continuously to engage in the
                improvement of not merely sesame oil, but also new products to
                meet the known demands of today and the unknown demands of the
                future.
              </p>
            </div>
          </div>
        </div>

        <div className="join">
          <div className="content">
          <h2>Certified Products</h2>
          <p>Our Products are 100% Natural</p>
          <h1>We deal with various natual products</h1>
          <div className="features">
              <p><AiFillCheckCircle className="icon"/> Sesame Oil</p>
              <p><AiFillCheckCircle className="icon"/> Dried Sesame Bean</p>
              <p><AiFillCheckCircle className="icon"/> Dried Grains</p>
              <p><AiFillCheckCircle className="icon"/> Natural Sesame Seeds</p>
          </div>
          <Link to="/shop"><button>Start Shopping</button></Link>
          </div>
          <div className="image">
            <img src={Sesame} alt="Sesame" />
          </div>
        </div>

        <Faq />

      </div>
      <Footer />
      <CopyRight />
    </div>
  );
}

export default About;
