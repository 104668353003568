import React from 'react'
import {MdPhone} from 'react-icons/md'
import {MdLocationOn} from 'react-icons/md'
import {MdEmail} from 'react-icons/md'
import ContactCard from './ContactCard'
import './ContactCards.css'

let cards = {"phone":["+263772214340","+263772214340"],
             "email":["info@opensesame.co.zw","admin@opensesame.co.zw"],
             "location":["10 Lych Close, Chisipite, Harare, Zimbabwe"]}

function ContactCards() {
  return (
    <div className="ContactCards">
        <h1>Get in Touch</h1>
        <div className="cards">
            <ContactCard details={cards["phone"]} icon={MdPhone}/>
            <ContactCard details={cards["email"]} icon={MdEmail}/>
            <ContactCard details={cards["location"]} icon={MdLocationOn}/>
        </div>
    </div>
  )
}

export default ContactCards