import React from "react";
import Navbar from "./../components/Navbar/Navbar";
import Footer from "./../components/Footer/Footer";
import CopyRight from "./../components/Footer/CopyRight";
import SesameSeeds from "./../assets/Products/Boxes/Seeds/200_blackwhite.png";
import SesameSeeds2 from "./../assets/Products/Boxes/Seeds/200_dehulled_polished.png";
import SesameOil from "./../assets/Products/Bottles/Macadamia/500_macadamia.png";
import SesameOil2 from "./../assets/Products/Bottles/Chilli Infused/500_chilli_infused.png";
import Background from  './../assets/Backgrounds/all-products.jpg'
import './css/AboutSesame.css'
import { Link } from "react-router-dom";

function AboutSesame() {
  return (
    <div className="AboutSesame">
      <Navbar />
      <div className="content screen-big">
        <h2>About Sesame</h2>
        <p className="description">
          Sesame is a flowering plant in the genus Sesamum, also called benne.
          Numerous wild relatives occur in Africa and a smaller number in India.
          It is widely naturalized in tropical regions around the world and is
          cultivated for its edible seeds, which grow in pods.
        </p>

        <div className="cards">

          <div className="row">
            <div className="image-card card">
              <img src={SesameSeeds} alt="Seeds" />
            </div>
            <div className="card">
              <p>
                When revealed from the husk, sesame seeds can be white, black,
                red, brown, or tan,-. depending on the variety of the sesame
                plant itself.
              </p>
            </div>
            <div className="card">
              <p>
                Most of the sesame seeds you’ll see in the U.S. are creamy
                white, while black sesame seeds are most commonly used in
                Chinese cooking.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="card">
              <p>
                Sesame seeds have many potential health benefits and have been
                used in folk medicine for thousands of years They may protect
                against heart disease, diabetes, and arthritis.
              </p>
            </div>
            <div className="card">
              <p>
                However, you may need to eat significant amounts — a small
                handful per day — to gain health benefits.<br/><br/> 
                1. Good Source of Fiber<br/> 
                2. May Lower Cholesterol and Triglycerides <br/> 
                3. Nutritious Source of Plant Protein <br/> 
                4. May Help Lower Blood Pressure Good Source of B Vitamins<br/> 
              </p>
            </div>
            <div className="image-card card">
              <img src={SesameSeeds2} alt="Seeds" />
            </div>
          </div>

          <div className="row">
            <div className="image-card card">
              <img src={SesameOil} alt="Seeds" />
            </div>
            <div className="card">
              <p>
                Sesame oil is an edible vegetable oil derived from sesame seeds.
                Besides being used as a cooking oil, it is used as a flavor
                enhancer in many cuisines, having a distinctive nutty aroma and
                taste. The oil is one of the earliest-known crop-based oils
              </p>
            </div>
            <div className="card">
              <p>
              <strong>Proper use</strong> <br/><br/>
                6 Ways to Make the Most of Toasted Sesame Oil<br/>
                – Drizzle it on soups. <br/>
                – Whisk it into vinaigrette. <br/>
                – Hit a stir-fry. <br/>
                – Use it to coat noodles. <br/>
                – Dress up popcorn. <br/>
                – Drizzle it over ice cream.<br/>
              </p>
            </div>
          </div>

          <div className="row">
            <div className="card">
              <p>
                <strong>Smoke point</strong> <br/><br/>
                Sesame oil has a high smoke point (410˚) and
                relatively neutral flavor. It’s a great general-purpose oil (use
                it for sautés, roasts, and more), but if it’s a big finish
                you’re looking for, use its nuttier sibling, toasted sesame oil.
              </p>
            </div>
            <div className="card">
              <p>
              <strong>Storage</strong> <br/><br/>
                Basically, you should store sesame oil like you store
                any other vegetable oil. That means you should keep unopened
                sesame it in a cool and dry area, away from sunlight or heat
                sources. The pantry is a perfect choice, but a cabinet in the
                kitchen will work too. Just make sure it’s not near the oven.
              </p>
            </div>
            <div className="image-card card">
              <img src={SesameOil2} alt="Seeds" />
            </div>
          </div>

        </div>

        <div className="button">
          <Link to='/shop'><button>Join Us Now</button></Link>
        </div>

      </div>
      <div className="image">
        <img src={Background} alt="background"/>
      </div>
      <Footer />
      <CopyRight />
    </div>
  );
}

export default AboutSesame;
