import React from 'react'
import './OurCard.css'
import {Link} from 'react-router-dom'

function OurCard(props) {
  return (
    <div className="OurCard" style={{backgroundImage:`url(${props.card[2]})`}}>
        <h2>{props.card[0]}</h2>
        <p>{props.card[1]}</p>
        <Link to="/shop"><button>Shop Now</button></Link>
    </div>
  )
}

export default OurCard