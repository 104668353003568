import React from "react";
import Navbar from "./../components/Navbar/Navbar";
import Footer from "./../components/Footer/Footer";
import CopyRight from "./../components/Footer/CopyRight";
import "./css/FarmersCorner.css";

import Farmers from "./../assets/Farmers/Farmers.jpg";

function FarmersCorner() {
  return (
    <div className="FarmersCorner">
      <Navbar />
      <div className="content screen-big">
        <div className="head">
          <h1>Farmer's Corner</h1>
          <p>
            Open sesame is a farmer led company that was founded in 2008. We
            have been dedicated to offering high quality products reputable for
            their value in creating a natural taste and exceptional flavors that
            define <strong>“open sesame“</strong>.
          </p>
        </div>
        <img className="farmers" src={Farmers} alt="Farmers" />
      </div>
      <Footer />
      <CopyRight />
    </div>
  );
}

export default FarmersCorner;
