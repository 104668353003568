import React from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../redux/reducers/cartReducer';
import './ProductCard.css'

function ProductCard(props) {

  const dispatch = useDispatch()

  const add = () => {
    toast.success('Added to Cart.');
    dispatch(addToCart(props.selector))
  }

  return (
    <div className='ProductCard'>
        <div className="image">
          <img alt="Product" src={props.details.image} />
        </div>
        <div className="details">
          <h2>{props.details.quantity} {props.details.name}</h2>
          <p>{props.details.category}</p>
          <div className="buy">
            <h4>${props.details.price}</h4>
            <button onClick={add}>Add to Cart</button>
          </div>
        </div>
        <Toaster position='bottom-right'/>
    </div>
  )
}

export default ProductCard
