import React from 'react'
import Logo from './../../assets/Branding/logo-sesame-transparent.png'
import './Footer.css'

function Footer() {
  return (
    <div className="Footer">
        <div className="describe">
            <div className="logo">
                <img src={Logo} alt="Logo" />
            </div>
            <p>We invest in our customers by creating products that include benefits 
            such as healthy skin which we believe promotes wellness and wellbeing.</p>
        </div>
        <div className="contact-us">
            <h2>Contact Us</h2>
            <div className="emails">
                <p>Get the latest news and products update from us.</p>
                <p>Email: info@opensesame.co.zw</p>
                <p>Email: sales@opensesame.co.zw</p>
            </div>
            <address>
                Address: 10 Lych Close, Chisipite, Harare, Zimbabwe
            </address>
            <div className="phone">
                <p>Call: +263772813232</p>
                <p>Call: +263772214340</p>
            </div>
        </div>
    </div>
  )
}

export default Footer