import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Hero from '../components/Hero/Hero'
import Introduction from '../components/Hero/Introduction'
import Join from '../components/Hero/Join'
import Collections from '../components/Hero/Collections'
import OurProducts from '../components/Hero/OurProducts'
import Footer from './../components/Footer/Footer'
import Off from '../components/Hero/Off'
import CopyRight from './../components/Footer/CopyRight'
// import Testimonials from './../components/Testimonial/Testimonials'
import './css/Home.css'
import { best_selling,seeds,trending_products} from './../api/products'


function Home() {
  return (
    <div className="Home">
      <Navbar />
      <Hero />
      <div className="content screen-big">
        <Introduction />
        <Join />
        <Collections collection={best_selling} head="Best Selling Products" />
        <OurProducts />
        <Off />
        <Collections collection={trending_products} head="Trending Products" />
        {/* <Testimonials /> */}
        <Collections collection={seeds} head="Quick Selection" />
      </div>
      <Footer />
      <CopyRight />
    </div>
  )
}

export default Home