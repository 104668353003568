import React from "react";
import Navbar from "./../components/Navbar/Navbar";
import Footer from "./../components/Footer/Footer";
import CopyRight from "./../components/Footer/CopyRight";
import "./css/Account.css";

function Account() {
  return (
    <div className="Account">
      <Navbar />
      <div className="content screen-big">
        <div className="login">
          <h2>Account Login</h2>
          <div className="card">
            <form onClick={e=>e.preventDefault()}>
              <div className="input">
                <p>Username or Email</p>
                <input type="text" placeholder="Enter Email or Username" />
              </div>
              <div className="input">
                <p>Password</p>
                <input type="password" placeholder="Enter Password" />
              </div>
                <input type="submit" value="Login" />
            </form>
          </div>
        </div>
      </div>
      <Footer />
      <CopyRight />
    </div>
  );
}

export default Account;
