import React from 'react'
import './ContactCard.css'

function ContactCard(props) {
  return (
    <div className="ContactCard">
        <div className="icon">
            <props.icon/>
        </div>
        <div className="info">
            {props.details.map((item,index)=>{
                return <p key={index}>{item}</p>
            })}
        </div>
        
    </div>
  )
}

export default ContactCard