import React from 'react'
import Navbar from './../components/Navbar/Navbar'
import Footer from './../components/Footer/Footer'
import CopyRight from './../components/Footer/CopyRight'
import ContactCards from './../components/Contact/ContactCards'
import ContactForm from '../components/Contact/ContactForm'
import './css/Contact.css'

function Contact() {
  return (
    <div className="Contact">
      <Navbar />
      <div className="content screen-big">
          <ContactCards />
          <ContactForm />
      </div>
      <Footer/>
      <CopyRight/>
    </div>
  )
}

export default Contact