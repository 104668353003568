import React, {useEffect, useState} from "react";
import "./Introduction.css";
import {
  CarouselProvider,
  Slider,
  Slide,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { bottle } from './../../api/products'


function Introduction() {

  const all_products = Object.keys(bottle).map((key,index) => 
    <Slide className="slide" key={index}>
      <img  className="product-image" src={bottle[key].image} alt="Product" />
    </Slide>
  )

  const [slides, setSlides] = useState(3);

  useEffect(() => {
    if(window.innerWidth > 900){
      setSlides(3);
    }else if(window.innerWidth>600){
      setSlides(2);
    }else{
      setSlides(1);
    }

    window.addEventListener('resize',()=>{
      if(window.innerWidth > 900){
        setSlides(3);
      }else if(window.innerWidth>600){
        setSlides(2);
      }else{
        setSlides(1);
      }
    })
  }, [])
  

  return (
    <div className="Introduction">
      <div className="greetings">
        <h1>Greetings</h1>
        <p>
          Being a leading company of sesame products since its inception in
          2008, Open Sesame has been dedicated on offering the uppermost
          excellence products centred on the traditional, natural taste and fine
          flavour that is
          <strong>
            <em>Open Sesame.</em>
          </strong>
        </p>
      </div>

      <div className="carousel">
        <CarouselProvider
          visibleSlides={slides}
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={all_products.length}
          isPlaying
          currentSlide={0}
          interval={3000}
          infinite={true}
          className="carousel-container"
        >
          <Slider className="slider">
            {all_products}
          </Slider>
        </CarouselProvider>
      </div>
    </div>
  );
}

export default Introduction;
