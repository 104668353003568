import React, {useState} from 'react'
import Logo from './../../assets/Branding/logo.jpg'
import {GiHamburgerMenu} from 'react-icons/gi'
import {MdClose} from 'react-icons/md'
import {TiShoppingCart} from 'react-icons/ti'
import './Navbar.css'
import { Link } from 'react-router-dom'

function Navbar() {

    let [isOpen, setOpen] = useState(false)

  return (
    <div className="Navbar" style={{position: isOpen ? 'fixed' : 'relative'}}>
        <img src={Logo} alt="Logo" />
        <ul style={{left: isOpen ? '0' : '-100%'}}>
            <Link to='/'><li>Home</li></Link>
            <Link to='/shop'><li>Shop</li></Link>
            <Link to='/about'><li>About</li></Link>
            <Link to='/team'><li>Team</li></Link>
            <Link to='/contact'><li>Contact</li></Link>
            <Link to='/account'><li>Account</li></Link>
            <Link to='/cart'><li className='cartIcon'><TiShoppingCart /></li></Link>
        </ul>
        <div className='hamburger-mobile'>
            {isOpen?<MdClose onClick={()=>{setOpen(!isOpen)}}/>:
            <GiHamburgerMenu onClick={()=>{setOpen(!isOpen)}}/> }
        </div>
    </div>
  )
}

export default Navbar