import React from "react";
// import { useSelector } from "react-redux";
// import { products } from "./../api/products";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import CopyRight from "../components/Footer/CopyRight";
import { useSelector } from "react-redux";
import { products } from "./../api/products";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import "./css/Checkout.css";
import { postData } from "../api/request";
import { v4 as uuid } from 'uuid';

function Checkout() {
  const cart = useSelector((state) => state);
  let navigate = useNavigate();

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (Object.keys(cart).length <= 1) navigate("/shop");
  }, [cart, navigate]);

  function handleSubmit() {
    if (name === "" || address === "" || phone === "") {
      toast.error("Fill the Fields");
    }else if(isNaN(parseInt(phone))){
      toast.error("Enter Valid Number");
    } else {
      let allproducts = "";
      Object.entries(cart).map(([key, value], i) =>
        key !== "_persist" && value !== 0
          ? (allproducts +=
              products[key].quantity +
              " " +
              products[key].name +
              " - (" +
              value +
              "), ")
          : ""
      );

      let body = {
        records: [
          {
            fields: {
              ID: uuid(),
              Name: name,
              Products: allproducts,
              Address: address,
              Phone: phone,
              Status: "Ordered",
            },
          },
        ],
      };

      postData(
        "https://api.airtable.com/v0/appuheIIyHtVAsAFh/Orders",
        body
      ).then((data) => {
        console.log(data);
      });

      toast.success("Successfully Ordered");
    }
  }

  return (
    <div>
      <Navbar />
      <div className="content screen-big">
        <form className="checkout" onClick={(e) => e.preventDefault()}>
          <input
            type="text"
            placeholder="Name"
            required
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <textarea
            placeholder="Enter your full address"
            required
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          ></textarea>
          <input
            type="tel"
            placeholder="Enter your Phone Number"
            required
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
          <button className="button" onClick={handleSubmit}>
            Order
          </button>
        </form>
      </div>
      <Footer />
      <CopyRight />

      <Toaster position="bottom-right" />
    </div>
  );
}

export default Checkout;
