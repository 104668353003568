import React from 'react'
import './CopyRight.css'
import {BsInstagram} from 'react-icons/bs'
import {BsFacebook} from 'react-icons/bs'
import {BsTwitter} from 'react-icons/bs'

function CopyRight() {
  return (
    <div className="CopyRight">
        <div className="right-content">
            <p>Copyright &#169; | opensesame | Website by Trendonline Digital</p>
        </div>
        <div className="social-media">
            <BsInstagram/>
            <BsFacebook/>
            <BsTwitter/>
        </div>
    </div>
  )
}

export default CopyRight