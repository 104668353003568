import React from 'react'
import './Products.css'
import { products } from './../../api/products.js'
import ProductCard from './ProductCard'

function Products() {
    const all_products = Object.keys(products).map(key => 
        <ProductCard key={products[key].id} details={products[key]} selector={key}></ProductCard>
    )

    return (
        <div className="Products">
            <div className="allProducts">
                {all_products}
            </div>
        </div>
    )
}

export default Products