import './Describe.css'

import React from 'react'

function Describe() {
  return (
    <div className="Describe">
        <div className="know-us">
          <h1>The team behind sesame</h1>
          <p>Open Sesame has been developed over a long period of time in which the Directors 
            <strong><em> BRENDAN SMITH</em></strong> and<strong><em> MASIMBA KANYEPI</em></strong> have put so much effort from the very establishment 
            of the introduction of the crop to large scale sesame production in Zimbabwe. Initially 
            the concept was to involve as many small scale farmers in the production of sesame, clean 
            and export. We started with 14 growers in Muzarabani and 3 years later we had over 1500 
            growers on our books, and covered most regions in 4 and 5 areas. Some of the areas covered 
            were Muzarabani, Mbire, Gokwe, Binga, Chipinge, Chiredzi and Chikombedzi. My passion for 
            sesame and sesame production has never waned so in my spare time I have tried to value add 
            to sesame seed delivered by small scale farmers. It is a concept I have worked on in the past 
            5 years and have learnt how to clean and process sesame into the products that I sell today.</p>
        </div>
    </div>
  )
}

export default Describe