import React from 'react'
import OurCard from './OurCard'
import './OurProducts.css'
import One from './../../assets/Sesame/2-2.png'
import Two from './../../assets/Sesame/3-1.png'
import Three from './../../assets/Sesame/1-1.png'

let ourcards = [
    ["Sesame Natural Seeds", "tiny oil-rich seeds which gives a lot of health benefits which include fiber, plant protein , vitamins and anti oxidants.", One],
    ["Sesame Dehulled seeds", "they are distinct with their white creamy colour because of the removed hull.", Two],
    ["Sesame Oil", "oil-rich seeds which gives a lot of health benefits which include fiber, plant protein , vitamins and anti oxidants.", Three]
]

function OurProducts() {

    const our_cards = ourcards.map((value, index)=> 
        <OurCard key={index} card={value}/>
    )

  return (
    <div className="OurProducts">
        {our_cards}
    </div>
  )
}

export default OurProducts