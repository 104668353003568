import React from 'react'
import FounderCard from './FounderCard'
import './Founders.css'
import Masimba from './../../assets/Partners/partner (1).jpg'
import Brenden from './../../assets/Partners/partner (3).jpg'

let founders = {
    "brendan_smith":{
        "name":"Brendan Smith",
        "position": "Co-Founder",
        "profile": Brenden
    },
    "masimba_kenyapi":{
        "name":"Masimba Kenyapi",
        "position": "Co-Founder",
        "profile": Masimba
    }
}

function Founders() {
  return (
    <div className="Founders">
        <FounderCard details={founders["brendan_smith"]}/>
        <FounderCard details={founders["masimba_kenyapi"]} />
    </div>
  )
}

export default Founders