import ChilliInfusedOil250 from './../assets/Products/Bottles/Chilli Infused/250_chilli_infused.png'
import ChilliInfusedOil500 from './../assets/Products/Bottles/Chilli Infused/500_chilli_infused.png'

import GarlicInfusedOil250 from './../assets/Products/Bottles/Garlic Infused/250_garlic_infused.png'
import GarlicInfusedOil500 from './../assets/Products/Bottles/Garlic Infused/500_garlic_infused.png'

import MacadamiaOil250 from './../assets/Products/Bottles/Macadamia/250_macadamia.png'
import MacadamiaOil500 from './../assets/Products/Bottles/Macadamia/500_macadamia.png'

import PecanNutOil250 from './../assets/Products/Bottles/Pecan Nut/250_pecan_nut.png'
import PecanNutOil500 from './../assets/Products/Bottles/Pecan Nut/500_pecan_nut.png'

import PureOrganicOil250 from './../assets/Products/Bottles/Pure Organic/250_pure_organic.png'
import PureOrganicOil500 from './../assets/Products/Bottles/Pure Organic/500_pure_organic.png'

import PureSesame250 from './../assets/Products/Bottles/Pure Sesame/250_pure_sesame.png'

import BlackWhite40 from './../assets/Products/Boxes/Seeds/40_blackwhite.png'
import BlackWhite200 from './../assets/Products/Boxes/Seeds/200_blackwhite.png'


import DehulledPolished40 from './../assets/Products/Boxes/Seeds/40_dehulled_polished.png'
import DehulledPolished200 from './../assets/Products/Boxes/Seeds/200_dehulled_polished.png'


import Natural200 from './../assets/Products/Boxes/Seeds/200_natural.png'

import BlackTahini375 from './../assets/Products/Jars/Tahini/375_black_tahini_butter.png'
import Tahini375 from './../assets/Products/Jars/Tahini/375_tahini_butter.png'

import PecanNut300 from './../assets/Products/Packets/Chips/300_pecan_nut_chips.png'
import DehulledSesame1000 from './../assets/Products/Packets/Seeds/1000_dehulled_seed.png'

export let bottle = {
    "chilli_infused_oil_250": {
        "id":1,
        "name": "Chilli Infused Oil",
        "quantity": "250ml",
        "category": "groceries",
        "price": "4.60",
        "image": ChilliInfusedOil250,
    },
    "garlic_infused_oil_250": {
        "id":2,
        "name": "Garlic Infused Oil",
        "quantity": "250ml",
        "category": "groceries",
        "price": "4.60",
        "image": GarlicInfusedOil250,
    },
    "macadamia_oil_250": {
        "id":3,
        "name": "Macadamia Oil",
        "quantity": "250ml",
        "category": "groceries",
        "price": "4.60",
        "image": MacadamiaOil250,
    },
    "pecan_nut_oil_250": {
        "id":4,
        "name": "Pecan Nut Oil",
        "quantity": "250ml",
        "category": "groceries",
        "price": "4.60",
        "image": PecanNutOil250,
    },
    "pure_organic_oil_250": {
        "id":5,
        "name": "Pure Organic Oil",
        "quantity": "250ml",
        "category": "groceries",
        "price": "4.60",
        "image": PureOrganicOil250,
    },
    "pure_sesame_oil_250": {
        "id":6,
        "name": "Pure Sesame Oil",
        "quantity": "250ml",
        "category": "groceries",
        "price": "4.60",
        "image":PureSesame250,
    },
    "chilli_infused_oil_500": {
        "id":7,
        "name": "Chilli Infused Oil",
        "quantity": "500ml",
        "category": "groceries",
        "price": "8.00",
        "image": ChilliInfusedOil500,
    },
    "garlic_infused_oil_500": {
        "id":8,
        "name": "Garlic Infused Oil",
        "quantity": "500ml",
        "category": "groceries",
        "price": "8.00",
        "image":GarlicInfusedOil500,
    },
    "macadamia_oil_500": {
        "id":9,
        "name": "Macadamia Oil",
        "quantity": "500ml",
        "category": "groceries",
        "price": "8.00",
        "image": MacadamiaOil500,
    },
    "pecan_nut_oil_500": {
        "id":10,
        "name": "Pecan Nut Oil",
        "quantity": "500ml",
        "category": "groceries",
        "price": "8.00",
        "image": PecanNutOil500,
    },
    "pure_organic_oil_500": {
        "id":11,
        "name": "Pure Organic Oil",
        "quantity": "500ml",
        "category": "groceries",
        "price": "8.00",
        "image": PureOrganicOil500,
    },
}

export let seeds = {
    "black_white_seeds_40":{
        "id":12,
        "name": "Black and White Seeds",
        "quantity": "40g",
        "category": "groceries",
        "price": "1.20",
        "image": BlackWhite40,
    },
    "black_white_seeds_200":{
        "id":13,
        "name": "Black and White Seeds",
        "quantity": "200g",
        "category": "groceries",
        "price": "2.50",
        "image": BlackWhite200,
    },
    "dehulled_polished_seeds_40":{
        "id":14,
        "name": "Dehulled Polished Seeds",
        "quantity": "40g",
        "category": "groceries",
        "price": "1.40",
        "image": DehulledPolished40,
    },
    "dehulled_polished_seeds_200":{
        "id":15,
        "name": "Dehulled Polished Seeds",
        "quantity": "200g",
        "category": "groceries",
        "price": "2.90",
        "image": DehulledPolished200,
    },
    "natural_seeds_200":{
        "id":16,
        "name": "Natural Seeds",
        "quantity": "200g",
        "category": "groceries",
        "price": "2.50",
        "image": Natural200,
    },
}

export let jars = {
    "black_tahini_butter_375":{
        "id":17,
        "name": "Black Tahini Butter",
        "quantity": "375g",
        "category": "groceries",
        "price": "8.00",
        "image": BlackTahini375,
    },
    "tahini_butter_375":{
        "id":18,
        "name": "Tahini Butter",
        "quantity": "375g",
        "category": "groceries",
        "price": "6.00",
        "image": Tahini375,
    }
}

export let packets = {
    "pecan_nut_chips_300":{
        "id":19,
        "name": "Pecan Nut Chips",
        "quantity": "300g",
        "category": "groceries",
        "price": "4.50",
        "image": PecanNut300,
    },
    "dehulled_sesame_1000":{
        "id":20,
        "name": "Dehulled Sesame",
        "quantity": "1000g",
        "category": "groceries",
        "price": "5.60",
        "image": DehulledSesame1000,
    }
}

export let best_selling={
    "garlic_infused_oil_250": {
        "id":2,
        "name": "Garlic Infused Oil",
        "quantity": "250ml",
        "category": "groceries",
        "price": "4.60",
        "image": GarlicInfusedOil250,
    },
    "macadamia_oil_250": {
        "id":3,
        "name": "Macadamia Oil",
        "quantity": "250ml",
        "category": "groceries",
        "price": "4.60",
        "image": MacadamiaOil250,
    },
    "chilli_infused_oil_500": {
        "id":7,
        "name": "Chilli Infused Oil",
        "quantity": "500ml",
        "category": "groceries",
        "price": "8.00",
        "image": ChilliInfusedOil500,
    },
    "pecan_nut_oil_500": {
        "id":10,
        "name": "Pecan Nut Oil",
        "quantity": "500ml",
        "category": "groceries",
        "price": "8.00",
        "image": PecanNutOil500,
    },
    "natural_seeds_200":{
        "id":16,
        "name": "Natural Seeds",
        "quantity": "200g",
        "category": "groceries",
        "price": "2.50",
        "image": Natural200,
    },
    "black_white_seeds_200":{
        "id":13,
        "name": "Black and White Seeds",
        "quantity": "200g",
        "category": "groceries",
        "price": "2.50",
        "image": BlackWhite200,
    },
}

export let trending_products = {
    "black_white_seeds_200":{
        "id":13,
        "name": "Black and White Seeds",
        "quantity": "200g",
        "category": "groceries",
        "price": "2.50",
        "image": BlackWhite200,
    },
    "tahini_butter_375":{
        "id":18,
        "name": "Tahini Butter",
        "quantity": "375g",
        "category": "groceries",
        "price": "6.00",
        "image": Tahini375,
    },
    "black_white_seeds_40":{
        "id":12,
        "name": "Black and White Seeds",
        "quantity": "40g",
        "category": "groceries",
        "price": "1.20",
        "image": BlackWhite40,
    },
}

export let products = {...bottle, ...seeds, ...jars, ...packets };